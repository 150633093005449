import React, { useEffect, useRef } from 'react'
import Logo from './img/webbot_logo_black.png'
import { Box, Fab, Fade, Slide, Stack, Typography } from '@mui/material'
import Intro from './components/Intro'
import WorkExperience from './components/WorkExperience'
import Projects from './components/Projects'
import Skills from './components/Skills'
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PropTypes from 'prop-types';

function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                top: -50,
                behavior: 'smooth',
            });
        }
    };


    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', right: { xs: 10, md: 35 }, top: { xs: "92vh", md: "92.5vh" } }}
            >
                {children}
            </Box>
        </Fade>
    );
}

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};


function Portfolio(props) {

    const scrollRef = useRef(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <>
            <Stack alignItems={"center"} ref={scrollRef} >
                <Box width={{ xs: "95%", md: "90%", lg: "80%" }} >
                    <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                        <Box component={"img"} src={Logo} draggable={false} alt='logo' mt={{ xs: 1, md: 2 }} id={"back-to-top-anchor"} />
                        <Stack direction={'row'} gap={0.5} alignItems={'center'} onClick={() => window.open('https://maps.app.goo.gl/Xk1bEGwMWhsQZHyz7')} sx={{ cursor: 'pointer' }}>
                            <LocationOnOutlinedIcon />
                            <Typography>Sharjah, UAE</Typography>
                        </Stack>
                    </Stack>
                    <Stack gap={{ xs: 2, md: 3, lg: 5 }}>
                        <Intro />
                        <WorkExperience />
                        <Projects />
                        <Skills />
                    </Stack>
                </Box>
            </Stack>

            <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top" sx={{ bgcolor: 'white' }}>
                    <KeyboardArrowUpIcon sx={{ color: '#2DCDCE' }} />
                </Fab>
            </ScrollTop>
        </>
    )
}

export default Portfolio