import { Avatar, Box, Card, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import ncpliLoco from './img/ncpliloco.png'
import itechLoco from './img/itechloco.jpeg'

function WorkExperience() {

  const workExperienceDetails = [{
    loco: ncpliLoco,
    role: "Web Developer",
    companyName: "Netcom Computers Private Limited",
    startDate: "Jan 2022",
    endDate: "Mar 2024",
    experienceYears: 2 + " Year",
    experienceMonths: 3 + " Months",
    link: "https://ncpli.com/"
  }, {
    loco: itechLoco,
    role: "Junior Executive",
    companyName: "iTech India Private Limited",
    startDate: "Sep 2019",
    endDate: "Feb 2020",
    experienceYears: "0 Year",
    experienceMonths: "6 Months",
    link: "https://itechindia.co/"
  }]

  const titleFontSize = { xs: 22, sm: 30, md: 18, lg: 20, xl: 30 }
  const cardFontSize = { xs: 12, sm: 20, md: 12, lg: 15, xl: 20 }


  return (
    <>
      <Box>
        <Divider />
        <Stack direction={{ md: "row" }} justifyContent={"space-between"} mt={5} >
          <Typography sx={{ color: "#2DCDCE", fontSize: titleFontSize }}>Work Experience</Typography>

          <Stack direction={{ md: "row" }} gap={{ xs: 2, sm: 5, md: 5, lg: 9, xl: 5 }} alignItems={"center"} justifyContent={"flex-end"} sx={{ mt: { xs: 3, md: 0 }, width: { xs: "100%", md: "80%" } }}>
            {workExperienceDetails.map((items, index) => (
              <Card elevation={5} onClick={() => window.open(items.link)} sx={{ borderRadius: "10px", cursor:"pointer" }}>
                <Stack gap={2} sx={{ ml: 2, mt: 2, mr: index === 0 ? { xs: 2 } : { xs: 7, sm: 10, md: 7, lg: 8 } }}>
                  <Stack direction={"row"} gap={3} >
                    <Avatar sx={{ width: 56, height: 56, overflow: 'hidden', border: "1px solid #404040" }}> <Box component={"img"} src={items.loco} sx={{ width: "100%", height: "100%", backgroundSize: "100% 100%" }} /> </Avatar>
                    <Stack gap={1}>
                      <Typography sx={{ fontSize: cardFontSize, fontFamily: "Ubuntu" }}>{items.role}</Typography>
                      <Typography sx={{ fontSize: cardFontSize, fontFamily: "fantasy" }}>{items.companyName}</Typography>
                      <Typography sx={{ fontSize: cardFontSize, fontFamily: "fantasy" }}>{items.startDate} - {items.endDate}</Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack alignItems={"center"} sx={{ mt: 2 }}>
                  <Divider sx={{ width: "93%" }} />
                </Stack>
                <Typography sx={{ fontSize: cardFontSize, fontFamily: "Poppins", textAlign: "end", mt: 1, mr: 2, mb: 1 }}>{items.experienceYears} {items.experienceMonths}</Typography>

              </Card>
            ))}
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export default WorkExperience