import { Box, Button, Card, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HtmlLogo from './img/htmllogo.png'
import CssLogo from './img/csslogo.png'
import MuiLogo from './img/muilogo.png'
import JavascriptLogo from './img/javascriptlogo.png'
import ReactLogo from './img/reactlogo.png'
import NodeLogo from './img/nodejslogo.png'
import ExpressLogo from './img/expressjslogo.png'
import MongodbLogo from './img/mongologo.png'
import AwsLogo from './img/awslogo.png'

function Skills() {
    const titleFontSize = { xs: 22, sm: 30, md: 18, lg: 20, xl: 30 }
    const skills = [{
        logo: HtmlLogo,
        title: 'HTML'
    }, {
        logo: CssLogo,
        title: 'CSS'
    }, {
        logo: MuiLogo,
        title: 'MUI'
    }, {
        logo: JavascriptLogo,
        title: 'JAVASCRIPT'
    }, {
        logo: ReactLogo,
        title: 'REACTJS'
    }, {
        logo: NodeLogo,
        title: 'NODEJS'
    }, {
        logo: ExpressLogo,
        title: 'EXRESSJS'
    }, {
        logo: MongodbLogo,
        title: 'MONGODB'
    },
    {
        logo: AwsLogo,
        title: 'AWS'
    }
    ]
    const commonHeightWidth = { xs: 160, sm: 80, md: 100, lg: 130, xl: 160 }
    const test = [...Array(skills.length)].map((_, index) => index + 1).filter((val) => val > 0 && val % 6 === 0).map((e) => e - 1)
    return (
        <>
            <Divider />
            <Typography sx={{ color: "#2DCDCE", fontSize: titleFontSize }}>Skills</Typography>
            <Grid container rowGap={10}>
                {skills.map((items, index) => (
                    <Grid item xs={12} sm={4} md={2}>
                        <Stack gap={2} alignItems={"center"}>
                            <Card sx={{
                                alignSelf: { xs: "center", md: index === 0 || index % 6 === 0 ? "flex-start" : test.includes(index) ? "flex-end" : "center" },
                                height: commonHeightWidth,
                                width: commonHeightWidth,
                                bgcolor: '#16213E',
                                borderRadius: "10px",
                                p: 1
                            }}>
                                <Stack justifyContent={'space-between'} alignItems={'center'} sx={{ height: '100%' }}>
                                    <Box
                                        component={"img"}
                                        src={items.logo}
                                        alt="Card Background"
                                        sx={{
                                            width: { xs: 110, sm: 40, md: 50, lg: 80, xl: 110 },
                                            height: { xs: 110, sm: 40, md: 50, lg: 80, xl: 110 },
                                            mt: 1
                                        }}
                                    />
                                    <Typography noWrap sx={{ fontSize: { xs: 20, sm: 12, md: 13, lg: 18, xl: 20 }, color: 'white', fontWeight: 'bold' }}>{items.title}</Typography>
                                </Stack>
                            </Card>
                        </Stack>
                    </Grid>
                ))}
            </Grid >

            <Divider sx={{ mt: 8 }} />
            <Typography sx={{ color: "#ff4d4d", mt: 5, textAlign: "center", fontSize: { xs: 15, sm: 30, md: 18, lg: 20, xl: 30 } }}>This website is currently being developed...</Typography>
            <Stack alignItems={"center"} mb={8} mt={5} >
                <Button variant='contained' onClick={() => window.open("https://drive.google.com/file/d/1X6jkQSBZsRX8EvXIZKgmAaD_b0rH0lJy/view?usp=drive_link")} sx={{ textTransform: "capitalize", borderRadius: "50px", bgcolor: "#2DCDCE", ":hover": { bgcolor: "#2DCDCE" } }}> <FileDownloadIcon sx={{ mr: 1 }} /> My Resume</Button>
            </Stack>
        </>
    )
}

export default Skills