import React from 'react'
import MyImage from './img/myimg.png'
import IndeedIcon from './img/indeedicon.svg'
import LinkedinIcon from './img/linkedinicon.svg'
import WhatsappIcon from './img/whatsappicon.svg'
import { Box, Button, Fade, Stack, Tooltip, Typography } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

function Intro() {
    const pastYear = 2022
    const currentYear = new Date()
    const experienceYears = currentYear.getFullYear() - pastYear + " Year"
    const experienceMonths = currentYear.getMonth() + 1 + " Months"
    const objective = `MERN stack developer with ${experienceYears} ${experienceMonths} of experience building scalable and efficient web applications and APIs. Proficient in JavaScript, MongoDB, Node.js, and React, with experience in deploying and hosting web applications using AWS, Nginx, and Ubuntu.`
    const iconsList = [IndeedIcon, LinkedinIcon, WhatsappIcon]
    const myNameFontsize = { xs: 35, sm: 40, md: 40, lg: 50, xl: 70 }


    return (
        <>
            <Stack direction={{ xs: "column-reverse", md: "row" }} justifyContent={{ xs: "center", md: "space-between" }} mt={2}>
                <Stack width={{ xs: "100%", md: "45%" }} justifyContent={"space-evenly"}>
                    <Typography sx={{ fontSize: { xs: 70, sm: 80, md: 80, lg: 100, xl: 140 }, fontFamily: "fantasy", mb: -1, color: "#3F3F3F" }}>My name</Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <Typography sx={{ fontSize: myNameFontsize, fontFamily: "fantasy", mt: { xs: -2, md: -3, lg: -5 }, color: "#3F3F3F" }}>is</Typography>
                        <Typography sx={{ fontSize: myNameFontsize, fontFamily: "fantasy", mt: { xs: -2, md: -3, lg: -5 }, color: "#2DCDCE" }}> Ithris Mohamed</Typography>
                    </Stack>
                    <Typography sx={{ mt: { xs: 3, md: 0 }, fontSize: { xs: 17, sm: 20, md: 14, lg: 15, xl: 20 }, textAlign: "justify" }}>{objective}</Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={3} mt={4}>
                        <Tooltip title="+971 564328419" TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }} placement="left-start">
                            <Button onClick={() => window.open("tel:+971564328419")} variant='contained' size='large' sx={{ borderRadius: "50px", bgcolor: "#2DCDCE", ":hover": { bgcolor: "#2DCDCE" } }}><PhoneIcon sx={{ mr: 1 }} />Call</Button>
                        </Tooltip>
                        <Tooltip TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }} title="ithrismohamed01@gmail.com" placement="right-start" sx={{ bgcolor: "#2DCDCE" }}>
                            <Button onClick={() => window.open("mailto:ithrismohamed01@gmail.com")} variant='contained' size='large' sx={{ borderRadius: "50px", bgcolor: "#2DCDCE", ":hover": { bgcolor: "#2DCDCE" } }}><MailOutlinedIcon sx={{ mr: 1 }} /> Gmail</Button>
                        </Tooltip>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={6} mt={5} >
                        {iconsList.map((items, index) => (
                            <Box component={"img"} onClick={index === 0 ? () => window.open("https://profile.indeed.com/p/ithrism-1hlqzk5") : index === 1 ? () => window.open("https://www.linkedin.com/in/ithrismohamed/") : () => window.open("https://api.whatsapp.com/send/?phone=%2B918220719108&text&type=phone_number&app_absent=0")} src={items} alt='indeed icon' sx={{ width: "40px", height: "40px", cursor: "pointer", mt: index === 0 ? 0 : 1 }} />
                        ))}
                    </Stack>
                </Stack>
                <Stack alignItems={"center"} width={{ xs: "100%", md: "45%" }} sx={{ mt: { xs: 3, md: 0 } }}>
                    <Box component={"img"} src={MyImage} draggable={false} alt='myimage' sx={{ width: { xs: "90%", md: "420px", lg: "500px", xl: "500px" }, height: { xs: "90%", md: "420px", lg: "500px", xl: "500px" } }} />
                </Stack>
            </Stack>
        </>
    )
}

export default Intro