import React, { useState } from 'react'
import { Box, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material'
import FaizLogo from './img/faizlogo.png'
import CrudLogo from './img/crudlogo.png'
import AssessmentLogo from './img/assessmentlogo.png'
import GillgallLogo from './img/gillgalllogo.png'
import TailsuiteLogo from './img/tailsuitelogo.png'
import LiveLogo from './img/livelogo.png'
import EdovorLogo from './img/edovorlogo.png'
import Tapshelflogo from './img/tapshelflogo.jpeg'


function Projects() {
  const titleFontSize = { xs: 22, sm: 30, md: 18, lg: 20, xl: 30 }
  const projects = [{
    title: 'Assessment Tool',
    status: "Ongoing",
    project: "Company",
    description: 'Enhancing Your Learning And Scoring Skills With Our Assessment Tool',
    image: AssessmentLogo,
    link: 'https://netcomeschool.com',
  }, {
    title: 'Faiz Studios',
    status: "Completed",
    project: "Personal",
    description: 'The Faiz Studios Portfolio Website is a meticulously crafted digital platform designed to showcase the exceptional work of Faiz Studios to a global audience.',
    image: FaizLogo,
    link: 'https://faizstudios.webbot.in',
  },
  {
    title: 'CRUD Operation',
    status: "Completed",
    project: "Personal",
    description: 'CRUD Operations is a dynamic static website that seamlessly empowers users to perform essential CRUD (Create, Read, Update, Delete) operations.',
    image: CrudLogo,
    link: 'https://crud.webbot.in',
  }, {
    title: 'Gillgall',
    status: "Completed",
    project: "Company",
    description: 'Gillgall is an online learning app personalized for CBSE, ICSE & State Board students.',
    image: GillgallLogo,
    link: 'https://gillgall.com',
  }, {
    title: 'Tailsuite',
    status: "Completed",
    project: "Company",
    description: 'easy to use tailor shop management system that could help you manage your tailor shop business very well with customers, their measurements, orders & payments.',
    image: TailsuiteLogo,
    link: 'https://tailsuite.com',
  }, {
    title: 'Video Presentation',
    status: "Completed",
    project: "Company",
    description: 'easy to use tailor shop management system that could help you manage your tailor shop business very well with customers, their measurements, orders & payments.',
    image: EdovorLogo,
    link: 'https://edovor.com',
  }, {
    title: 'Ecommerce',
    status: "Completed",
    project: "Personal",
    description: 'easy to use tailor shop management system that could help you manage your tailor shop business very well with customers, their measurements, orders & payments.',
    image: Tapshelflogo,
    link: 'https://tapshelf.webbot.in',
  },]

  const [isHovered, setIsHovered] = useState(false);
  const commonHeightWidth = { xs: 350, sm: 250, md: 180, lg: 210, xl: 270 }
  const test = [...Array(projects.length)].map((_, index) => index + 1).filter((val) => val > 0 && val % 4 === 0).map((e) => e - 1)

  return (
    <>
      <Divider />
      <Typography sx={{ color: "#2DCDCE", fontSize: titleFontSize }}>Projects</Typography>

      <Grid container rowGap={10}>
        {projects.map((items, index) => (
          <Grid item xs={12} sm={6} md={3}>
            <Stack alignItems={"center"}>
              <Card onClick={() => window.open(items.link)} sx={{
                alignSelf: { xs: "center", md: index === 0 || index % 4 === 0 ? "flex-start" : test.includes(index) ? "flex-end" : "center" },
                position: 'relative',
                overflow: 'hidden',
                height: commonHeightWidth,
                width: commonHeightWidth,
                cursor: "pointer",
                borderRadius: "5px"
              }}
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(false)}>
                {/* Background Image */}
                <Box
                  component={"img"}
                  src={items.image}
                  alt="Card Background"
                  sx={{
                    width: '100%',
                    filter: isHovered === index ? 'blur(8px)' : 'blur(0px)',
                    opacity: isHovered === index ? 0.3 : 0.7,
                  }}
                />
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  color: 'white',
                  display: isHovered === index ? "initial" : "none"
                }}>
                  <CardContent sx={{ color: "black", fontFamily: "Poppins", fontWeight: "bold" }}>
                    <Typography noWrap sx={{ fontSize: { xs: 20, md: 13, lg: 18, xl: 20 } }}>{items.title}</Typography>
                    <Typography noWrap sx={{ color: "#176869", mt: 0.5, fontSize: { xs: 10, md: 7, lg: 9, xl: 12 } }}>{index === 1 || index === 2 || index === 6 ? "Personal Project" : "Office Project"}</Typography>
                    <Typography noWrap sx={{ color: "#176869", mt: 0.5, fontSize: { xs: 10, md: 7, lg: 9, xl: 12 } }}>Status : {index === 0 ? "Ongoing" : "Completed"}</Typography>
                    <Box component={"img"} src={LiveLogo} sx={{ width: 50, mt: 1 }} />
                  </CardContent>
                </Box>
              </Card>
            </Stack>
          </Grid>
        ))}
      </Grid>

    </>
  )
}

export default Projects